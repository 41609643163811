import firebase from "firebase";
import React from "react";
import { Button, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactPlayer from 'react-player'

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// const defaultLayoutPluginInstance = defaultLayoutPlugin();
// import Custombutton from "./Custombutton";
// import { history } from "../../history";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
class App extends React.Component {
  state = {
    fullscreen: false,
    successAlert: false,
    tvnamelist:[],
    testid:1,
    videoplaying:false,
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };
  componentWillUnmount() {}

  startmodal(){
    var name = this.state.tvnamelist[0];
    this.setState({
      name:name,
      successAlert:true,
     
    },()=>{
      setTimeout(() => {
        var newremovelist = this.state.tvnamelist;
        newremovelist.shift();
        console.log(newremovelist);
        this.setState({
          name:"",
          tvnamelist:newremovelist,
          successAlert:false
        },()=>{
          if(newremovelist.length>0){
            setTimeout(() => {
              this.startmodal()
            }, 500);
        
          }
        });  
      }, 5000);
    })
  }
  componentDidMount() {
    console.log("In Eventinfo");
  
    var newdata = false;
    firebase
      .database()
      .ref("tvdata")
      .on("child_added", (snap) => {
        console.log("In child_added");
        if (newdata) {
          console.log(snap.val());
          if("name" in snap.val()){
            var tvlist = this.state.tvnamelist;
            tvlist.push(snap.val().name);
            console.log(tvlist);
            this.setState({
              tvnamelist:tvlist
            },()=>{
              if(tvlist.length===1){
                this.startmodal();
              }
              // this.startmodal()
            })
              
          }
        }
      });
    firebase
      .database()
      .ref("tvdata")
      .once("value", (snap) => {
        newdata = true;
        console.log(snap.val());
      });
  }
  requestFullScreen(element) {
    // Supports most browsers and their versions.
    var requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;
    requestMethod.call(element);
    this.setState({
      fullscreen: true,
    });
  }
  render() {
    // var data = this.props.eventdata.documents;
    return (
      <div>
        <SweetAlert
          showConfirm={false}
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <div style={{ color: "#000" }} className="sweet-alert-text">
            <h1>Thank you {this.state.name}</h1>

            <h3>Together, let's watch as this small act blossoms into a beautiful tree, symbolizing our commitment to sustainability and growth. Thank you for your contribution to a more sustainable world! </h3>
            {/* <br /> */}
            {/* <h1>{this.state.name}</h1> */}
            
          </div>
        </SweetAlert>
        {/* <Modal></Modal> */}
        <ReactPlayer
          url="/sats2.mp4"
          loop={true}
          playing={this.state.videoplaying}
          style={{ position: "absolute" }}
          width={"100%"}
          height={"100%"}
        />
        <div style={{ position: "relative" }}>
          {this.state.fullscreen ? null : (
            <div style={{paddingTop:30,paddingLeft:30}}>
            <Button
            style={{fontSize:20}}
              onClick={() => {
                console.log("GOinf full screen");
                var elem = document.body; // Make the body go full screen.
                this.requestFullScreen(elem);
                this.setState({
                  videoplaying:true
                })
              }}
              color="primary"
            >
              {" "}
              Full Screen
            </Button>
            
            <Button
            style={{fontSize:20}}
              onClick={() => {
               
                this.setState({
                  videoplaying:true
                })
              }}
              color="primary"
            >
              {" "}
             PLay video
            </Button>
            <Button
            id="mainbt"
            style={{fontSize:20,marginLeft:30,zIndex:10000000000000}}
              onClick={() => {
                var tvlist = this.state.tvnamelist;
                tvlist.push("Test Name"+this.state.testid);
                console.log(tvlist);
                this.setState({
                  testid:this.state.testid+1,
                  tvnamelist:tvlist
                },()=>{
                  if(tvlist.length===1){
                    this.startmodal();
                  }
                 
                })
                

                
              }}
              color="primary"
            >
              {" "}
             Test
            </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
