// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/database'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config ={
  apiKey: "AIzaSyD26WxotKIyWgeOKF-_5VMyIKE2xR2I_6A",
  authDomain: "sats-inauguration.firebaseapp.com",
  databaseURL: "https://sats-inauguration-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sats-inauguration",
  storageBucket: "sats-inauguration.appspot.com",
  messagingSenderId: "777820455887",
  appId: "1:777820455887:web:c046dfce839460e0fc88ba",
  measurementId: "G-9D7HL5YYKT"
};
firebase.initializeApp(config)
firebase.analytics();